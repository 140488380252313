import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    svg: {
        width: 40,
        height: 40,
        display: 'block',
        //marginRight: theme.spacing(2),
        position: 'absolute',
        left: '18px'
    },
    content: {
        width: '100px',
        position: 'absolute',
        left: '36%',
    },
    title: {
        color: theme.palette.tmnaColors.secondary.lightGray,
    },
    body: {
        color: theme.palette.tmnaColors.secondary.slateGray,
    }
}));