import { Config } from '@backstage/config';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';

export const platformChargebackApiRef = createApiRef<IPlatformChargebackApi>({
    id: 'plugin.platform-chargebacks',
});

type PlatformChargebackApiOptions = {
    configApi: Config;
    identityApi: IdentityApi;
}

type PlatformChargebackParams = {
    costCenterId: string,
    wbsId: string,
    chargebackMonth: string;
}

interface IPlatformChargebackApi {
    fetchPlatformServiceChargeback(params: PlatformChargebackParams): Promise<any>;
    getS3File(month_year: string, formatted_date: string, chargeback_name: string): Promise<any>;
    getChargebackFile(month_year: string, chargeback_name: string): Promise<any>;
}

export class PlatformChargebackApi implements IPlatformChargebackApi {
    private configApi: Config;
    private identityApi: IdentityApi;

    constructor(options: PlatformChargebackApiOptions) {
        this.configApi = options.configApi;
        this.identityApi = options.identityApi;
    }

    public async fetchPlatformServiceChargeback(params: PlatformChargebackParams) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url: any = new URL(`${backendUrl}/api/platform-chargebacks/fetch-chargebacks`);
        const token = await this.identityApi.getCredentials()

        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                ...(token && { Authorization: `Bearer ${token.token}` }),
            },
            body: JSON.stringify({
                date: params?.chargebackMonth,
                costCenterId: params?.costCenterId,
                wbsId: params?.wbsId
            }),
        }).catch(err => { throw new Error(`${err.code} : ${err.message}`) });
        return await response.json();
    }

    public async getS3File(month_year: string, formatted_date: string, chargeback_name: string) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url: any = new URL(`${backendUrl}/api/platform-chargebacks/download-${chargeback_name}?month_year=${month_year}`)
        const token = await this.identityApi.getCredentials();
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                ...(token && { Authorization: `Bearer ${token.token}` }),
                responseType: 'blob'
            }
        }).catch(err => { throw new Error(`${err.code}: ${err.message}`) });
        if (response.status === 500) {
            throw new Error(`${chargeback_name} chargebacks have not been generated for the month of ${formatted_date}`)
        }
        return await response.blob();
    }

    public async getChargebackFile(month_year: string, chargeback_name: string) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url: any = new URL(`${backendUrl}/api/platform-chargebacks/download-chargeback?month_year=${month_year}&chargeback_name=${chargeback_name}`);  
        const token = await this.identityApi.getCredentials();
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                ...(token && { Authorization: `Bearer ${token.token}` }),
                responseType: 'blob'
            },
        }).catch(err => { throw new Error(`${err.code} : ${err.message}`) });
        return await response.blob();
    }
}