/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Typography} from '@material-ui/core';
import Logo from './logo.svg';
import { useSidebarOpenState } from '@backstage/core-components';
import {useStyles} from './styles';

const LogoIcon = () => {
  const classes = useStyles();
  const { isOpen } = useSidebarOpenState();

  return (
      <div className={classes.container}>
        <img className={classes.svg} src={Logo} alt='logo' />
        {isOpen && <div className={classes.content}>
          <Typography className={classes.title} variant='h4'>Chofer</Typography>
          <Typography className={classes.body} variant='body2'>Cloud Portal</Typography>
        </div>}
      </div>
  );
};

export default LogoIcon;
