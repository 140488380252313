import { IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import { Config } from '@backstage/config';

type ChatbotApiOptions = {
  configApi: Config;
  identityApi: IdentityApi;
};

interface IChatbotApi {
  chat(params: {
    question: string;
    sessionId: string;
  }): Promise<ReadableStreamDefaultReader<Uint8Array>>;
}

export const chatbotApiRef = createApiRef<IChatbotApi>({
  id: 'plugin.chatbot',
});

export class ChatbotApi implements IChatbotApi {
  private configApi: Config;
  private identityApi: IdentityApi;

  constructor(options: ChatbotApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
  }

  async chat(params: { question: string; sessionId: string }) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/chatbot/chat`;
    const queryParams = new URLSearchParams(params).toString();
    const response = await fetch(`${requestUrl}?${queryParams}`, {
      method: 'GET',
      credentials: 'include',
      headers: { Authorization: `Bearer ${token.token}` },
    });
    if (!response.ok || !response.body) {
      throw response.statusText;
    }

    const reader = response.body.getReader();
    return reader;
  }
}
