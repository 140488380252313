import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { chatbotApiRef, ChatbotApi } from './api';

export const chatbotPlugin = createPlugin({
  id: 'chatbot',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: chatbotApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ configApi, identityApi }) =>
        new ChatbotApi({ identityApi, configApi }),
    }),
  ],
});

export const ChatbotPage = chatbotPlugin.provide(
  createRoutableExtension({
    name: 'ChatbotPage',
    component: () => import('./components/Chatbot').then(m => m.Chatbot),
    mountPoint: rootRouteRef,
  }),
);
